import useQueryParam from 'hooks/use-query-param';
import { set } from 'util/session-storage';

const travelcoupClientIds = [
  'dev-travelui2018-app',
  'integration-travelui2018-app',
  'staging-travelui2018-app',
  'travelui-app',
];

const citis2goClientIds = [
  'citis2go-app',
  'dev-citis2go-app',
  'integration-c2g-mobile-app',
];

const deluxeClientIds = [
  'dev-deluxe-webapp',
  'integration-deluxe-webapp',
  'staging-deluxe-webapp',
  'deluxe-webapp',
];

const miClientIds = [
  'dev-management-app',
  'integration-management-app',
  'staging-management-app',
  'management-app',
];

function useClientId() {
  // use a travelcoup id if we don't get one for some reason
  const clientId = useQueryParam('client_id');
  console.log('Received client_id: ', clientId);
  if (!clientId) return 'travelcoup';

  // save to session storage for later use/convenience
  set('client_id', clientId);

  if (travelcoupClientIds.includes(clientId)) {
    return 'travelcoup';
  } else if (citis2goClientIds.includes(clientId)) {
    return 'citis2go';
  } else if (deluxeClientIds.includes(clientId)) {
    return 'deluxe';
  } else if (miClientIds.includes(clientId)) {
    return 'mi';
  } else {
    // fall back to travelcoup components if we get an unsupported id
    return 'travelcoup';
  }
}

export default useClientId;
