import log from 'loglevel';

import * as api from '../api';
import { ParsedQuery } from 'query-string';
import { set } from 'util/session-storage';
import { AuthPrompt } from './types';

type HandlePromptResponse =
  | { status: 'success' }
  | { status: 'error'; msg: string }
  | { status: 'redirect'; uri: string };

export async function handlePrompt(
  prompt: AuthPrompt,
): Promise<HandlePromptResponse> {
  log.info('Next step: ', prompt.type);
  switch (prompt.type) {
    case 'auth':
      log.info('Saving auth sid for further use', prompt.sid);
      set('sid', prompt.sid);
      log.info('Authentication required');
      return { status: 'success' };
    case 'error':
      return {
        status: 'error',
        msg: 'Received error result: ' + prompt.error_description,
      };
    case 'response':
      switch (prompt.mode) {
        case 'fragment':
          log.info(
            'Received fragment response, redirecting to',
            prompt.parameters!.uri,
          );
          return { status: 'redirect', uri: prompt.parameters!.uri };
        case 'query':
          log.info(
            'Received query response, redirecting to',
            prompt.parameters!.uri,
          );
          return { status: 'redirect', uri: prompt.parameters!.uri };
        default:
          log.error('Unimplemented response mode', prompt.mode);
          return {
            status: 'error',
            msg: 'Something unexpected happened, not sure what to do next!',
          };
      }
    default:
      return {
        status: 'error',
        msg: 'Received unexpected prompt type, not sure what to do!',
      };
  }
}

export async function getInstructions(params: ParsedQuery<string>) {
  log.info('Getting instructions on how to proceed:', params);

  if (params['nonce']) {
    log.info(
      'Saving received nonce ',
      params['nonce'],
      'for later verification',
    );
    set('nonce', params['nonce']);
  }

  const prompt = await api.authorize(params);
  return handlePrompt(prompt as AuthPrompt);
}
