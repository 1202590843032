import * as React from 'react';

import { useForm } from 'react-hook-form';

import useStatus from 'hooks/use-status';
import { login } from 'services/srp-auth';

import { GridBox } from 'components/box';
import Button from 'components/button';
import FormErrors from 'components/form-errors';
import Input from 'components/inputs/floating';
import Logo from 'components/logo';
import StatusText from 'components/status-text';
import { Link, Text } from 'components/text';
import useClientId from 'hooks/use-client-id';
import * as Styles from './styles';

interface FormData {
  email: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const { status, Transition } = useStatus();
  const [apiError, setApiError] = React.useState('');
  const { register, handleSubmit, errors } = useForm<FormData>();

  const client = useClientId();
  const isTcDeluxe = client.includes('deluxe');
  const AllowSignup = client !== 'mi' && !isTcDeluxe;

  const onSubmit = (data: FormData) => {
    setApiError('');
    Transition.loading();
    login(data).then((res) => {
      switch (res.status) {
        case 'error':
          setApiError(res.msg);
          Transition.error();
          break;
        case 'redirect':
          window.location.replace(res.uri);
          break;
        default:
          console.log('Final login result: ', res);
          Transition.success();
      }
    });
  };

  return (
    <Styles.Wrapper style={{ background: isTcDeluxe ? '#282828' : 'auto' }}>
      <Logo mb={4} />
      {apiError && (
        <Text color="error.dark" fontSize={1} fontWeight="bold" mb={3}>
          {apiError}
        </Text>
      )}
      <FormErrors errors={errors} />
      <Styles.Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Email"
          ref={register({ required: true })}
          inputProps={{
            name: 'email',
            type: 'email',
          }}
        />

        <Input
          label="Password"
          ref={register({ required: true })}
          inputProps={{
            name: 'password',
            type: 'password',
          }}
        />

        <Button
          type="submit"
          disabled={status === 'loading'}
          kind="filled"
          variant="primary"
          py={3}
          mt={2}
        >
          <StatusText fontSize={2} message="Log in" status={status} />
        </Button>
        <GridBox gridGap={3} alignItems="center" fontSize={2} mt={3}>
          {AllowSignup && (
            <Text>
              Don't have an account? <Link to="/sign-up">Sign up here</Link>
            </Text>
          )}
          <Text color={isTcDeluxe ? '#fff' : 'auto'}>
            Forgot your password?{' '}
            <Link to={`/reset-password${window.location.search}`}>
              <Text color={isTcDeluxe ? '#eac554' : 'auto'}>Reset it here</Text>
            </Link>
          </Text>
        </GridBox>
      </Styles.Form>
    </Styles.Wrapper>
  );
};

export default LoginForm;
