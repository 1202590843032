import * as React from 'react';

import log from 'loglevel';
import qs, { ParsedQuery } from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import * as Constants from 'global-constants';
import { getInstructions } from '../../services/oidc-auth';

import { Heading } from 'components/text';
import { FlexBox, Box } from 'components/box';
import { TailSpinner } from 'components/activity-indicators';
import SVG from 'components/svg';

function queryParamsAreValid(params: ParsedQuery<string>) {
  return Constants.REQUIRED_QUERY_PARAMS.every((p) => Boolean(params[p]));
}

const Authenticate: React.FC = () => {
  const [error, setError] = React.useState('');
  const router = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    const query = qs.parse(location.search);

    async function handleParams(pathname: string) {
      const res = await getInstructions(query);

      switch (res.status) {
        case 'success':
          router.push({
            pathname,
            search: qs.stringify(query),
          });
          break;
        case 'error':
          setError(res.msg);
          break;
        case 'redirect':
          window.location.replace(res.uri);
          break;
      }
    }

    if (queryParamsAreValid(query)) {
      log.info(
        'Got called externally with OpenID Conect authentication request parameters',
      );
      log.info('Subject ID: ', query['subjectId']);

      if (query['id']) {
        handleParams('/new-password');
      } else if (query['action']) {
        // TODO: maybe use this for /reset-password as well?
        handleParams('/sign-up');
      } else {
        handleParams('/login');
      }
    } else {
      log.error(
        'Invalid OpenID Connect authentication request; make sure all required query parameters are present.',
      );
    }
  }, [location.search, router]);

  return (
    <FlexBox flex="1" alignItems="center" justifyContent="center">
      <Box textAlign="center">
        <SVG icon={TailSpinner} fontSize={6} color="primary.main" />
        {error && <Heading color="error.main">{error}</Heading>}
      </Box>
    </FlexBox>
  );
};

export default Authenticate;
