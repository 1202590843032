import log from 'loglevel';
import qs from 'query-string';

import * as srpClientFactory from 'thinbus-srp/browser.js';
import { scrypt } from 'scrypt-js';

import * as api from '../api';
import { LoginPayload, SRP2Challenge } from './types';
import { completeOidcLogin } from './login';
import { Credentials, SignupPayload, Organization } from 'api/types';
import { UserInfo } from 'components/signup-form/types';
import getenv from 'util/getenv';
import { get } from 'util/session-storage';
import { Result, ValidationError } from 'util/types';

const hostname = getenv('REACT_APP_PUBLIC_BASE_URL');

const rfc5054 = {
  N_base10:
    '21766174458617435773191008891802753781907668374255538511144643224689886235383840957210909013086056401571399717235807266581649606472148410291413364152197364477180887395655483738115072677402235101762521901569820740293149529620419333266262073471054548368736039519702486226506248861060256971802984953561121442680157668000761429988222457090413873973970171927093992114751765168063614761119615476233422096442783117971236371647333871414335895773474667308967050807005509320424799678417036867928316761272274230314067548291133582479583061439577559347101961771406173684378522703483495337037655006751328447510550299250924469288819',
  g_base10: '2',
  k_base16: '5b9e8ef059c6b32ea59fc1d322d37f04aa30bae5aa9003b8321e21ddb04e300',
};

const SRP6ClientSession = srpClientFactory(
  rfc5054.N_base10,
  rfc5054.g_base10,
  rfc5054.k_base16,
);

function answerChallenge(
  srp2Challenge: SRP2Challenge,
  client: AnyObject,
  email: string,
  derivedKey: string,
  attemptsLeft: number,
) {
  const start = performance.now();
  log.info('Answering challenge...');
  log.info('Initializing srp client step 1 with email/key', email, derivedKey);
  client.step1(srp2Challenge.sub, derivedKey);
  const s2Result = client.step2(srp2Challenge.salt, srp2Challenge.b);
  log.info(
    'Performed SRP6a client calculations in: ',
    Math.round(performance.now() - start),
    'ms',
  );

  const responseLink = srp2Challenge._links['srp-response'];
  const s3 = {
    session: srp2Challenge.session,
    a: s2Result.A,
    m1: s2Result.M1,
    auth_sid: get<string>('sid')!,
  };
  return completeOidcLogin(responseLink.href, s3, attemptsLeft);
}

async function scryptEncodePassword(sub: string, password: string) {
  const start = performance.now();
  log.info('Deriving scrypt password for ', sub, password);
  const enc = new TextEncoder();
  const normalizedPwd = enc.encode(password.normalize('NFKC'));
  const salt = enc.encode('AdditionalLoginSalt' + sub);

  const N = 4096;
  const r = 4;
  const p = 1;
  const dkLen = 32;

  const key = await scrypt(normalizedPwd, salt, N, r, p, dkLen);

  const b64key = btoa(
    new Uint8Array(key).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      '',
    ),
  );
  log.info('Scrypt calculation complete, got password: ', b64key);
  log.info(
    'Performed scrypt key expansion in ',
    Math.round(performance.now() - start),
    'ms',
  );

  return b64key;
}

export async function login(
  user: LoginPayload,
  derivedKey?: string,
  attemptsLeft = 1,
) {
  log.info('Attempting login...');
  const canonEmail = user.email.normalize('NFKC').toLowerCase().trim();
  const srp1ChallengeRequest = { identifier: canonEmail };
  const client = new SRP6ClientSession();

  try {
    const challenge = await api.srpChallenge(srp1ChallengeRequest);
    if (derivedKey) {
      log.info('Already got expanded key, using this one: ', derivedKey);
      return answerChallenge(
        challenge as SRP2Challenge,
        client,
        canonEmail,
        derivedKey,
        attemptsLeft,
      );
    } else {
      log.info('Need to expand password into proper key...');
      const key = await scryptEncodePassword(challenge.sub, user.password);

      return answerChallenge(
        challenge as SRP2Challenge,
        client,
        canonEmail,
        key,
        attemptsLeft,
      );
    }
  } catch (err) {
    log.error('Caught error while trying to perform SRP challenge', err);
    return { status: 'error' as const, msg: 'Unable to login' };
  }
}

export async function getAnonymousSub() {
  const result = await api.anonymousSub();
  return result;
}

export async function registerUser(user: UserInfo): Promise<Result<string>> {
  log.debug('Trying to register user with info:', user);
  const client = new SRP6ClientSession();
  const baseUrl = get('base_url');

  const key = await scryptEncodePassword(user.sub, user.password);
  log.info('Expanded password received:', key);
  const salt = client.generateRandomSalt();
  log.info(
    'Generating SRP verifier for sub',
    user.sub,
    'password',
    key,
    'and salt',
    salt,
  );
  const verif = client.generateVerifier(salt, user.sub, key);
  const credentials: Credentials = {
    kdf: 'SCRYPT_16384_8_1_32',
    srp_salt: salt,
    srp_verifier: verif,
    type: 'SRP6A',
  };

  const organization: Organization = {
    orgId: user.organization,
    dunsNumber: '',
    name: '',
    affiliate: false,
    travelAgency: false,
  };

  const payload: SignupPayload = {
    credentials,
    sub: user.sub,
    email: user.email,
    phone_number: user.phone_number,
    given_name: user.given_name,
    family_name: user.family_name,
    suffix: user.suffix,
    organization: organization,
    baseUrl,
  };

  const res = await api.register(payload);
  if (res.status >= 400) {
    return {
      status: 'error',
      msg: res.errors.map((e: ValidationError) => e.defaultMessage).join('\n'),
    };
  } else {
    return {
      status: 'success',
      payload: res.sub,
    };
  }
}

export async function getSubjectId(
  identifier: string,
): Promise<Result<string>> {
  const body = identifier.includes('@')
    ? { email: identifier }
    : { sub: identifier };

  const res = await api.checkUser(body);
  log.info('Received response from user-check api: ', res);

  if (res.status >= 400) {
    return {
      status: 'error',
      msg: res.errors.map((e: ValidationError) => e.defaultMessage).join('\n'),
    };
  } else {
    return {
      status: 'success',
      payload: res.sub,
    };
  }
}

function getRedirectPage(site: string | undefined) {
  switch (site) {
    case 'https://citis2go.com':
      return '/oauth';

    default:
      return '/oauth/callback';
  }
}

export async function requestResetPassword(
  email: string,
): Promise<Result<any>> {
  log.info('Attempting to send password reset email to', email);
  const baseUrl = get('base_url');
  const clientId = get('client_id');
  const canonEmail = email.normalize('NFKC').toLowerCase().trim();

  const subResponse = await getSubjectId(canonEmail);

  const query = qs.parse(window.location.search);

  if (subResponse.status === 'success') {
    const params = {
      response_type: 'id_token token',
      client_id: clientId,
      redirect_uri: baseUrl + getRedirectPage(baseUrl),
      scope: query.scope,
      claims: query.claims,
      nonce: get('nonce'),
      sub: subResponse.payload,
      baseUrl: hostname,
      display: 'popup',
    };

    const res = await api.sendPasswordResetEmail(params);
    log.info('Received send password reset email response: ', res);
    return { status: 'success', payload: res };
  } else {
    // Probably failed to find a user with the given email, send back the 'missing email' error
    return subResponse;
  }
}

export async function resetPassword(
  id: string,
  password: string,
): Promise<Result<any>> {
  log.info('Requesting password reset for user with id', id);
  const subResponse = await getSubjectId(id);

  if (subResponse.status === 'success') {
    const sub = subResponse.payload;
    log.info('subject ID received: ', sub);
    const key = await scryptEncodePassword(sub, password);
    const client = new SRP6ClientSession();
    const salt = client.generateRandomSalt();
    log.debug(
      'Generating SRP verifier for sub',
      sub,
      'password',
      password,
      'and salt',
      salt,
    );
    const verif = client.generateVerifier(salt, sub, key);
    const credentials: Credentials = {
      kdf: 'SCRYPT_16384_8_1_32',
      srp_salt: salt,
      srp_verifier: verif,
      type: 'SRP6A',
    };

    const res = await api.resetPassword({ credentials, sub });
    log.info('Received password reset response: ', res);
    return { status: 'success', payload: res };
  } else {
    // Probably failed to find a user with the given sub*, send back the 'missing email' error
    return subResponse;
  }
}
