import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-grow: 1;
  position: relative;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  top: 64px;
  left: 64px;
  width: 256px;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
  flex-grow: 1;
`;

export const SideContent = styled.div<{ bg: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55%;
  background-image: ${({ bg }) => `url(${bg})`};
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Overlay = styled.div`
  position: absolute;
  background-color: rgba(58, 200, 196, 0.7);
  padding: 64px 32px;
  width: 80%;

  & > :not(:first-child) {
    margin-top: ${({ theme }) => theme.space[4]};
  }
`;

export const OverlayItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

export const OverlayText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  text-transform: uppercase;
`;
