import log from 'loglevel';
import { ParsedQuery } from 'query-string';
import wretch from 'wretch';

import { SRP3ChallengeResponse } from 'services/types';
import getenv from 'util/getenv';

import * as Types from './types';

const baseUrl = getenv('REACT_APP_API_BASE_URL');

export async function authorize(params: ParsedQuery<string>) {
  const url = baseUrl + '/authorize';
  log.trace('Attempting to authorize at', url, 'using payload ', params);
  const res = await wretch(url, {}).query(params).get().json();

  return res;
}

export async function srpChallenge(challenge: Types.SRPChallengeRequest) {
  const url = baseUrl + '/srp/challenge';
  log.trace('Performing SRP challenge at', url, 'using payload', challenge);
  const res = await wretch(url, {
    headers: { 'Content-Type': 'application/json' },
  })
    .post(challenge)
    .json();

  return res;
}

export async function postConfirmation(
  url: string,
  payload: SRP3ChallengeResponse,
) {
  log.trace('Posting confirmation to', url, 'using payload', payload);
  const res = await wretch(url, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })
    .post(payload)
    .badRequest((err) => JSON.parse(err.message))
    .unauthorized((err) => {
      try {
        return JSON.parse(err.message);
      } catch (e) {
        return { status: err.status, message: 'Invalid email or password' };
      }
    })
    .json();

  return res;
}

export async function anonymousSub() {
  const url = baseUrl + '/users/anonymous';
  log.trace('Attempting to get anonymous sub at ', url);
  const res = await wretch(url, {
    headers: { 'Content-Type': 'application/json' },
  })
    .post()
    .json();

  return res;
}

export async function register(payload: Types.SignupPayload) {
  const url = baseUrl + '/users/sign-up';
  log.trace('Attempting to register user at', url, 'using payload', payload);
  const res = await wretch(url, {
    headers: { 'Content-Type': 'application/json' },
  })
    .post(payload)
    .badRequest((err) => JSON.parse(err.message))
    .unauthorized((err) => JSON.parse(err.message))
    .json();

  return res;
}

export async function checkUser(payload: Types.CheckUserPayload) {
  const url = baseUrl + '/users/user-check';
  log.trace('Checking if user exists at', url, 'using payload', payload);
  const res = await wretch(url, {
    headers: { 'Content-Type': 'application/json' },
  })
    .post(payload)
    .badRequest((err) => JSON.parse(err.message))
    .unauthorized((err) => JSON.parse(err.message))
    .json();

  return res;
}

export async function sendPasswordResetEmail(payload: Types.CheckUserPayload) {
  const url = baseUrl + '/users/password-reset-email';
  log.trace('Requesting pasword reset email at', url, 'using payload', payload);
  const res = await wretch(url, {
    headers: { 'Content-Type': 'application/json' },
  })
    .post(payload)
    .badRequest((err) => JSON.parse(err.message))
    .unauthorized((err) => JSON.parse(err.message))
    .json()
    .catch((err) => {
      log.error('Caught error!', err);
      return {
        error: 'unknown',
        error_description:
          'Received unexpected error, unable to send password reset email.',
      };
    });

  return res;
}

export async function resetPassword(payload: Types.ResetPasswordPayload) {
  const url = baseUrl + '/users/password-reset';
  log.trace('Requesting new password at', url, 'using payload', payload);
  const res = await wretch(url, {
    headers: { 'Content-Type': 'application/json' },
  })
    .post(payload)
    .badRequest((err) => JSON.parse(err.message))
    .unauthorized((err) => JSON.parse(err.message))
    .notFound((err) => JSON.parse(err.message))
    .json()
    .catch((err) => {
      log.error('Caught error!', err);
      return {
        error: 'unknown',
        error_description:
          'Received unexpected error, aborting password reset.',
      };
    });

  return res;
}
