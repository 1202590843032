function getenv(prop: string): string {
  const env = process.env[prop];
  if (!env) {
    throw new Error(
      `Environment variable ${prop} not set or could not be found. Did you spell it correctly?`,
    );
  }
  return env;
}

export default getenv;
