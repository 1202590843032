import styled from 'styled-components';
import { color, compose, layout, space, typography } from 'styled-system';

import { Link as RouterLink } from 'react-router-dom';

import { mkTransitions } from 'util/styles/helpers';
import { textTransform } from 'util/styles/mixins';
import Props from './types';

const textSystem = compose(color, layout, space, textTransform, typography);

export const Heading = styled.h1<Props>`
  margin: 0;

  ${textSystem}
`;
Heading.displayName = 'Common::Heading';
Heading.defaultProps = {
  fontWeight: 'bold',
  lineHeight: 'title',
};

export const Label = styled.label<Props>(textSystem);
Label.displayName = 'Common::Label';

export const Paragraph = styled.p<Props>(textSystem);
Paragraph.displayName = 'Common::Paragraph';
Paragraph.defaultProps = {
  lineHeight: 'copy',
};

export const Text = styled.span<Props>(textSystem);
Text.displayName = 'Common::Text';

export const Link = styled(RouterLink)<Props>`
  ${textSystem}

  color: ${({ theme }) => theme.colors.secondary.main};
  cursor: pointer;
  text-decoration: underline;
  transition: ${({ theme }) =>
    mkTransitions(theme.transitions.duration.short, 'color')};

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.dark};
  }
`;
Link.displayName = 'Common::Link';

export const ExternalLink = styled.a<Props>`
  ${textSystem}

  color: ${({ theme }) => theme.colors.secondary.main};
  cursor: pointer;
  text-decoration: underline;
  transition: ${({ theme }) =>
    mkTransitions(theme.transitions.duration.short, 'color')};

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.dark};
  }
`;
ExternalLink.displayName = 'Common::ExternalLink';

export type TextProps = Props;
