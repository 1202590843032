import * as React from 'react';

import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import Authenticate from 'pages/authenticate';
import LoginPage from 'pages/login';
import NewPasswordPage from 'pages/new-password';
import ResetPasswordPage from 'pages/reset-password';
import SignupPage from 'pages/sign-up';
import useClientId from 'hooks/use-client-id';

const Routes: React.FC = () => {
  const clientId = useClientId();

  const allowSignup = clientId !== 'mi' && clientId !== 'deluxe';

  return (
    <React.Suspense fallback="Loading...">
      <Switch>
        <Route exact path="/">
          {(props: RouteComponentProps) => (
            <Redirect
              to={{ pathname: '/authenticate', search: props.location.search }}
            />
          )}
        </Route>

        <Route path="/authenticate">
          <Authenticate />
        </Route>

        <Route path="/login">
          <LoginPage />
        </Route>

        {allowSignup && (
          <Route path="/sign-up">
            <SignupPage />
          </Route>
        )}

        <Route path="/reset-password">
          <ResetPasswordPage />
        </Route>

        <Route path="/new-password">
          <NewPasswordPage />
        </Route>
      </Switch>
    </React.Suspense>
  );
};

export default Routes;
