// Spacing is really finnicky with "pill" shaped inputs.
// Unfortunately the two reasonable paddings from our theme
// (8px, 16px) are too small and too large respectively.

// Setting some overrides here so the input variants
// are at least consistently off-scale in their padding.

export const PADDING_TOP = '14px';
export const PADDING_BOTTOM = '14px';
export const PADDING_LEFT = '20px';

export const FLOATING_LABEL_PADDING_TOP = '20px';
export const FLOATING_LABEL_PADDING_BOTTOM = '8px';
