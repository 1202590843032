import * as React from 'react';

import * as Styles from './styles';

const FloatingLabelInput = React.forwardRef<HTMLInputElement, any>(
  ({ inputProps, label, ...rest }, ref) => {
    const { id, name, placeholder, ...restInputProps } = inputProps;

    const inputId = id || name;
    const inputLabel = label || placeholder;

    return (
      <Styles.Wrapper {...rest}>
        <Styles.Input
          ref={ref}
          id={inputId}
          name={name}
          placeholder={inputLabel}
          {...restInputProps}
        />
        <Styles.Label htmlFor={inputId}>{inputLabel}</Styles.Label>
      </Styles.Wrapper>
    );
  },
);

export default FloatingLabelInput;
