import * as React from 'react';

import qs, { ParsedQuery } from 'query-string';
import { useLocation } from 'react-router-dom';

import { UserInfo } from 'components/signup-form/types';

import Layout from 'components/layouts/default';
import SignupForm from 'components/signup-form';

function getProvidedField(
  params: ParsedQuery<string>,
  k: string,
): string | undefined {
  if (params[k] && typeof params[k] === 'string') {
    return params[k] as string;
  } else {
    return undefined;
  }
}

const SignupPage: React.FC = () => {
  const location = useLocation();
  const [providedFields] = React.useState(() => {
    const fields = {} as UserInfo;
    const params = qs.parse(location.search);

    const email = getProvidedField(params, 'email');
    const family_name = getProvidedField(params, 'family_name');
    const given_name = getProvidedField(params, 'given_name');
    const middle_name = getProvidedField(params, 'middle_name');
    const organization = getProvidedField(params, 'organization');
    const phone_number = getProvidedField(params, 'phone_number');
    const suffix = getProvidedField(params, 'suffix');
    const sub = getProvidedField(params, 'sub');

    if (email) fields.email = email;
    if (family_name) fields.family_name = family_name;
    if (given_name) fields.given_name = given_name;
    if (middle_name) fields.middle_name = middle_name;
    if (organization) fields.organization = organization;
    if (phone_number) fields.phone_number = phone_number;
    if (suffix) fields.suffix = suffix;
    if (sub) fields.sub = sub;

    return fields;
  });

  return (
    <Layout>
      <SignupForm provided={providedFields} />
    </Layout>
  );
};

export default SignupPage;
