import * as React from 'react';

import { TailSpinner } from 'components/activity-indicators';
import { Text, TextProps } from 'components/text';

interface OwnProps {
  status: 'error' | 'success' | 'loading' | 'initial';
  message: string;
}

type Props = OwnProps & TextProps;

const StatusText: React.FC<Props> = ({ message, status, ...rest }) => {
  const [tempMsg, setTempMsg] = React.useState('');

  React.useEffect(() => {
    if (status === 'success') {
      setTempMsg('Success!');
      setTimeout(() => setTempMsg(message), 2500);
    }
  }, [message, status]);

  switch (status) {
    case 'loading':
      return (
        <>
          <Text {...rest} mr={3}>
            Loading
          </Text>
          <TailSpinner fontSize="2rem" />
        </>
      );
    case 'success':
      return <Text {...rest}>{tempMsg}</Text>;
    case 'error':
    default:
      return <Text {...rest}>{message}</Text>;
  }
};

export default StatusText;
