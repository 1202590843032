import styled from 'styled-components';

import * as spacing from './spacing';

import { mkTransitions } from 'util/styles/helpers';

const Input = styled.input`
  -webkit-appearance: none;
  width: 100%;
  border: none;
  padding-top: ${spacing.PADDING_TOP};
  padding-bottom: ${spacing.PADDING_BOTTOM};
  padding-left: ${spacing.PADDING_LEFT};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  border-radius: ${({ theme }) => theme.radii.pill};
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
  transition: ${({ theme }) =>
    mkTransitions(theme.transitions.duration.standard, 'box-shadow')};

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(142, 223, 220, 0.3),
      0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(142, 223, 220, 0.3);
  }
`;
Input.displayName = 'Common::Input';

export default Input;
