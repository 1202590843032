import * as React from 'react';

import { BrandContext } from 'App';
import SVG, { SVGProps } from 'components/svg';

type Props = Omit<
  SVGAttrs & SVGProps,
  'fontFamily' | 'fontSize' | 'fontStyle' | 'fontWeight' | 'letterSpacing'
>;

type LogoComponent = React.LazyExoticComponent<React.ComponentType<any>>;

const getLogo = (provider: string) =>
  React.lazy(() => import(`../../logos/logo.${provider}`));

const Logo: React.FC<Props> = (props) => {
  const [logo, setLogo] = React.useState<undefined | LogoComponent>(undefined);
  const provider = React.useContext(BrandContext);

  React.useEffect(() => {
    setLogo(getLogo(provider));
  }, [provider]);

  if (!logo) {
    return null;
  }

  return (
    <React.Suspense fallback={null}>
      <SVG icon={logo} {...props} />
    </React.Suspense>
  );
};

export default Logo;
