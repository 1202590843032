/**
 * Breakpoints for media queries.
 *
 * @property sm - Small devices (landscape phones)
 * @property md - Medium devices (tablets)
 * @property lg - Large devices (laptops)
 * @property xl - Extra large devices (desktops)
 * @property xxl - Extra extra large devices (large desktops)
 */
export const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1536px',
};
