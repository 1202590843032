import * as React from 'react';

import Layout from 'components/layouts/default';
import LoginForm from 'components/login-form';

const LoginPage: React.FC = () => {
  return (
    <Layout>
      <LoginForm />
    </Layout>
  );
};

export default LoginPage;
