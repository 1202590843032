import * as React from 'react';

import { LinksProps as Props } from './types';

import { ExternalLink } from 'components/text';

import * as Styles from './styles';

const links = [
  { to: '/how-it-works', label: 'How citis2go works' },
  { to: '/contact-us', label: 'Contact us' },
  { to: '/faqs', label: 'FAQs' },
  { to: '/privacy', label: 'Privacy' },
];

const Citis2GoLinks: React.FC<Props> = ({ locale }) => {
  return (
    <Styles.List>
      {links.map(({ to, label }) => (
        <Styles.ListItem key={to}>
          <ExternalLink href={`https://beta.citis2go.com/${locale}` + to}>
            {label}
          </ExternalLink>
        </Styles.ListItem>
      ))}
    </Styles.List>
  );
};

export default Citis2GoLinks;
