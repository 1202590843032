import log from 'loglevel';

import * as randomString from 'random-string';

import * as api from '../api';
import { SRP3ChallengeResponse, AuthPrompt } from './types';
import { handlePrompt, getInstructions } from './oidc-auth';
import { set, get } from 'util/session-storage';
import getenv from 'util/getenv';

export async function completeOidcLogin(
  confirmationLink: string,
  srpPayload: SRP3ChallengeResponse,
  attemptsLeft: number,
) {
  log.info('Posting response with available cookies to: ', confirmationLink);
  const res = await api.postConfirmation(confirmationLink, srpPayload);
  if (res.error) {
    if (
      res.error === 'invalid_session_id' ||
      res.error_description.match(/auth.*sid/)
    ) {
      if (attemptsLeft > 0) {
        log.info(
          'No valid session found, trying to get a new one for ourselves',
        );
        const newNonce = randomString({ length: 20 });
        set('nonce', newNonce);
        log.info('Saving a new nonce for later verification: ', newNonce);
        const params = {
          response_type: 'id_token token',
          client_id: getenv('REACT_APP_OWN_APP_ID'),
          redirect_uri: getenv('REACT_APP_PUBLIC_BASE_URL') + '/account',
          scope: 'openid profile email',
          nonce: get('nonce')!,
        };
        return getInstructions(params);
      }
    }
  } else if (res.status === 401) {
    return { status: 'error' as const, msg: res.message };
  }
  log.info('Authenticated: ', res);
  return handlePrompt(res as AuthPrompt);
}
