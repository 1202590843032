import * as React from 'react';

import { FieldError } from 'react-hook-form';

import { Text } from 'components/text';

type Errors = { [key: string]: FieldError | undefined };
interface OwnProps {
  errors: Errors;
}

type Props = OwnProps;

function toMessage(errors: Errors) {
  return Object.values(errors)
    .map((e) => e?.message)
    .filter(Boolean)
    .join('\n');
}

const FormErrors: React.FC<Props> = ({ errors }) => {
  const message = toMessage(errors);
  if (!message) return null;

  return (
    <Text
      color="error.main"
      fontSize={1}
      mb={3}
      style={{ whiteSpace: 'pre-line' }}
    >
      {message}
    </Text>
  );
};

export default FormErrors;
