import useQueryParam from 'hooks/use-query-param';

export function useBaseUrl(): string {
  const clientId = useQueryParam('client_id');

  switch (clientId) {
    case 'dev-travelui2018-app':
    case 'dev-management-app':
    case 'dev-citis2go-app':
      return 'https://localdev.travelcoup.com:3000';
    case 'dev-deluxe-webapp':
      return 'https://localdev.travelcoup.com:4200';

    case 'integration-travelui2018-app':
      return 'https://travelui2018-integration.travelcoup.com';
    case 'staging-travelui2018-app':
      return 'https://travelui2018-staging.travelcoup.com';
    case 'travelui-app':
      return 'https://www.travelcoupexperiences.com';

    case 'integration-management-app':
      return 'https://management-integration.travelcoup.com';
    case 'staging-management-app':
      return 'https://management-staging.travelcoup.com';
    case 'management-app':
      return 'https://management.travelcoup.com';

    case 'citis2go-app':
      return 'https://www.citis2go.com';

    case 'integration-deluxe-webapp':
      return 'https://www-integration.travelcoupdeluxe.com';
    case 'staging-deluxe-webapp':
      return 'https://www-staging.travelcoupdeluxe.com';
    case 'deluxe-webapp':
      return 'https://www.travelcoupdeluxe.com';

    default:
      return 'https://www.travelcoup.com';
  }
}
