import { breakpoints } from './common';

/**
 * Border widths
 */
const borders: string[] = [
  'none',
  '1px solid',
  '2px solid',
  '4px solid',
  '8px solid',
  '16px solid',
  '32px solid',
];

const colors = {
  nearBlack: '#111',
  darkGray: '#333',
  midGray: '#555',
  gray: '#777',
  silver: '#999',
  lightSilver: '#aaa',
  moonGray: '#ccc',
  lightGray: '#eee',
  nearWhite: '#f0f0f0',
  white: '#fff',
  azure: '#00AEEF',
  transparent: 'transparent',
  blacks: [
    'rgba(0, 0, 0, 0.0125)',
    'rgba(0, 0, 0, 0.025)',
    'rgba(0, 0, 0, 0.05)',
    'rgba(0, 0, 0, 0.1)',
    'rgba(0, 0, 0, 0.2)',
    'rgba(0, 0, 0, 0.3)',
    'rgba(0, 0, 0, 0.4)',
    'rgba(0, 0, 0, 0.5)',
    'rgba(0, 0, 0, 0.6)',
    'rgba(0, 0, 0, 0.7)',
    'rgba(0, 0, 0, 0.8)',
    'rgba(0, 0, 0, 0.9)',
  ],
  whites: [
    'rgba(255, 255, 255, 0.0125)',
    'rgba(255, 255, 255, 0.025)',
    'rgba(255, 255, 255, 0.05)',
    'rgba(255, 255, 255, 0.1)',
    'rgba(255, 255, 255, 0.2)',
    'rgba(255, 255, 255, 0.3)',
    'rgba(255, 255, 255, 0.4)',
    'rgba(255, 255, 255, 0.5)',
    'rgba(255, 255, 255, 0.6)',
    'rgba(255, 255, 255, 0.7)',
    'rgba(255, 255, 255, 0.8)',
    'rgba(255, 255, 255, 0.9)',
  ],
};

/**
 * Generally stick to fontSizes[1..6]
 * 7 and 8 can be OK sparingly for hero/marketing titles
 * while 0 is probably a bit too small and hard to read.
 */

const fontSizes = [
  '1.2rem',
  '1.4rem',
  '1.6rem',
  '2rem',
  '2.4rem',
  '3.6rem',
  '4.8rem',
  '8rem',
  '9.6rem',
];

const fontWeights = [400, 700] as const;

/**
 * Often referred to as tracking. Lowercase text should not be tracked, while
 * it is often useful to track uppercase text for improved readability. For
 * large text, it's also OK to use negative tracking for aesthetic purposes.
 *
 * @namespace
 * @property normal - Default spacing, potentially useful for resetting based on breakpoint
 * @property tracked - Go-to tracking for uppercase text
 * @property tight - Negative tracking, potentially nice for large titles
 * @property mega - Extra spaced out tracking for uppercase text
 */
const letterSpacings = {
  normal: 'normal',
  tracked: '0.1em',
  tight: '-0.05em',
  mega: '0.25em',
};

/**
 * @namespace
 * @property solid - Text that doesn't wrap
 * @property title - Self explanatory :^)
 * @property copy - Meant for body text
 */
const lineHeights = {
  solid: 1,
  title: 1.25,
  copy: 1.5,
};

const radii = {
  none: '0px',
  tight: '0px',
  normal: '0px',
  rounded: '0px',
  pill: '0px',
  circle: '100%',
};

const shadows = {
  blurred: '0px 0px 8px 2px rgba(0, 0, 0, 0.2)',
  focusedInput:
    '0 0 0 1px rgba(142, 223, 220, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(142, 223, 220, 0.3)',
  halo: '1px 1px 10px rgba(204, 204, 204, 0.9)',
  input:
    '0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0,0,0,0.07), 0 1px 1.5px 0 rgba(0,0,0,0.05)',
  none: 'none',
  normal: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
  sharp: '0px 0px 4px 2px rgba(0, 0, 0, 0.2)',
  subtle: '1px 1px 4px rgba(0, 0, 0, 0.1)',
};

const sizes = [
  '16px',
  '32px',
  '64px',
  '128px',
  '256px',
  '512px',
  '768px',
  '1024px',
  '1536px',
  '1920px',
] as const;

const space = [
  '0px',
  '4px',
  '8px',
  '16px',
  '32px',
  '64px',
  '128px',
  '256px',
  '512px',
];

const transitions = {
  duration: {
    shortest: '150ms',
    shorter: '200ms',
    short: '250ms',
    standard: '300ms',
    complex: '375ms',
    entering: '225ms',
    leaving: '195ms',
  },
};

const base = {
  borders,
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  radii,
  shadows,
  sizes,
  space,
  transitions,
};

const light = {
  ...base,
  colors: {
    ...colors,
    primary: {
      lightest: '#ebdaa1',
      light: '#e4ce82',
      main: '#dbbc54',
      dark: '#b49a45',
      darkest: '#8c7836',
    },
    secondary: {
      lightest: '#73848f',
      light: '#455b6a',
      main: '#001e33',
      dark: '#00192a',
      darkest: '#001421',
    },
    background: {
      default: '#f8f7f8',
      paper: '#ffffff',
    },
    error: {
      lightest: '#ffd5d5',
      light: '#ff9c9c',
      main: '#ff0000',
      dark: '#cb0000',
      darkest: '#7b0000',
    },
  },
};

// ignore dark for now until things are set up
// const dark = {
//   ...light,
//   colors: {
//     ...light.colors,
//     background: {
//       default: '#1b1b1b',
//       paper: '#333',
//     },
//   },
// };

const theme = {
  ...light,
};

export type Theme = typeof theme;
export default theme;
