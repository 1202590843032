import * as React from 'react';

import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { useBaseUrl } from 'hooks/use-base-url';
import useClientId from 'hooks/use-client-id';
import useQueryParam from 'hooks/use-query-param';
import getenv from 'util/getenv';
import { set } from 'util/session-storage';

import Routes from 'routes';

import travelcoup from './themes/theme.travelcoup';
import citis2go from './themes/theme.citis2go';
import deluxe from './themes/theme.deluxe';
import mi from './themes/theme.travelcoup';
import './App.css';

const themes = {
  travelcoup,
  citis2go,
  deluxe,
  mi,
};

let fallbackBrand: 'travelcoup' | 'citis2go' | 'deluxe' | 'mi';
const currentEnv = getenv('REACT_APP_ENV');
if (currentEnv === 'development') {
  fallbackBrand = getenv('REACT_APP_WHITELABEL') as
    | 'travelcoup'
    | 'citis2go'
    | 'deluxe'
    | 'mi';
}

const App: React.FC = () => {
  const provider = useClientId() || fallbackBrand;
  const baseUrl = useBaseUrl();
  const action = useQueryParam('action');

  React.useEffect(() => {
    set('base_url', baseUrl);
    set('mi-action', action);
  }, [baseUrl, action]);

  if (!provider) return null;

  return (
    <ThemeProvider theme={themes[provider]}>
      <IntlProvider messages={{}} locale={'en'}>
        <BrowserRouter>
          <BrandContext.Provider value={provider}>
            <Routes />
          </BrandContext.Provider>
        </BrowserRouter>
      </IntlProvider>
    </ThemeProvider>
  );
};

export const BrandContext = React.createContext('travelcoup');

export default App;
