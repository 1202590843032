import * as React from 'react';

import { useIntl } from 'react-intl';

import { FlexBox } from 'components/box';
import { Switch, Provider } from 'components/provider-renderer';
import TravelcoupLinks from './links.travelcoup';
import Citis2GoLinks from './links.citis2go';

const Footer: React.FC = () => {
  const { locale } = useIntl();

  return (
    <FlexBox as="nav" width="100%">
      <Switch>
        <Provider name="travelcoup">
          <TravelcoupLinks locale={locale} />
        </Provider>

        <Provider name="citis2go">
          <Citis2GoLinks locale={locale} />
        </Provider>
      </Switch>
    </FlexBox>
  );
};

export default Footer;
