import * as yup from 'yup';

import { FormValues } from './types';

const schema = yup.object().shape<FormValues>({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter an email address'),
  given_name: yup.string().required('Please enter your first name'),
  family_name: yup.string().required('Please enter your last name'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .required('Please enter a password'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords do not match')
    .required(),
});

export default schema;
