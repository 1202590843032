import * as React from 'react';

import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import useStatus from 'hooks/use-status';
import { getAnonymousSub, login, registerUser } from 'services/srp-auth';
import { FormValues, UserInfo } from './types';
import validationSchema from './validation-schema';

import Button from 'components/button';
import Input from 'components/inputs/floating';
import FormErrors from 'components/form-errors';
import Logo from 'components/logo';
import StatusText from 'components/status-text';
import { Text } from 'components/text';

import * as Styles from './styles';
import { yupResolver } from '@hookform/resolvers';
import { get } from 'util/session-storage';

interface OwnProps {
  provided: UserInfo;
}

type Props = OwnProps;

const resolver = yupResolver<FormValues>(validationSchema);

const SignupForm: React.FC<Props> = ({ provided }) => {
  const router = useHistory();
  const [apiError, setApiError] = React.useState('');
  const { status, Transition } = useStatus();
  const { register, handleSubmit, errors } = useForm({
    resolver,
    defaultValues: {
      given_name: provided.given_name,
      family_name: provided.family_name,
      email: provided.email,
      confirmPassword: '',
      password: '',
    },
  });

  const signUp = (data: FormValues, provided: UserInfo, sub?: string) =>
    registerUser({ ...data, ...provided, sub: sub || provided.sub }).then(
      (res) => {
        console.log('res:', res);
        if (res.status === 'error') {
          setApiError(res.msg);
          Transition.error();
        } else {
          const baseUrl = get('base_url');
          const action = get('mi-action');

          // Admin creating new user from MI
          if (action === 'signup') {
            window.location.replace(baseUrl + '/oauth/callback?action=signup');
          }

          login({
            email: data.email,
            password: data.password,
          }).then((res) => {
            switch (res.status) {
              case 'error':
                setApiError(res.msg);
                Transition.error();
                setTimeout(() => router.replace('/login'), 1600);
                break;
              case 'redirect':
                window.location.replace(res.uri);
                break;
              default:
                console.log('Final login result: ', res);
                Transition.success();
            }
          });
        }
      },
    );

  const onSubmit = (data: FormValues) => {
    Transition.loading();
    if (!provided.sub) {
      getAnonymousSub().then(({ sub }) => signUp(data, provided, sub));
    } else {
      signUp(data, provided);
    }
  };

  return (
    <Styles.Wrapper>
      <Logo mb={4} />
      {apiError && (
        <Text color="error.dark" fontSize={1} fontWeight="bold" mb={3}>
          {apiError}
        </Text>
      )}
      <FormErrors errors={errors} />
      <Styles.Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="First name"
          ref={register({ required: true })}
          inputProps={{
            name: 'given_name',
            type: 'text',
            readOnly: Boolean(provided.given_name),
          }}
        />

        <Input
          label="Last name"
          ref={register({ required: true })}
          inputProps={{
            name: 'family_name',
            type: 'text',
            readOnly: Boolean(provided.family_name),
          }}
        />

        <Input
          label="Email"
          ref={register({ required: true })}
          inputProps={{
            name: 'email',
            type: 'email',
            readOnly: Boolean(provided.email),
          }}
        />

        <Input
          label="Password"
          ref={register({ required: true })}
          inputProps={{
            name: 'password',
            type: 'password',
          }}
        />

        <Input
          label="Confirm password"
          ref={register({ required: true })}
          inputProps={{
            name: 'confirmPassword',
            type: 'password',
          }}
        />

        <Button
          disabled={status === 'loading'}
          kind="filled"
          variant="primary"
          type="submit"
          py={3}
          mt={2}
        >
          <StatusText message="Sign up" status={status} />
        </Button>
      </Styles.Form>
    </Styles.Wrapper>
  );
};

export default SignupForm;
