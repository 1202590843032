import * as React from 'react';

import Footer from 'components/footer';
import { RenderAt } from 'components/mq-render';
import SVG from 'components/svg';

import bg from 'assets/img/beach-boat-bg.jpg';
import Calendar from 'assets/svg/calendar';
import Compass from 'assets/svg/compass';
import Voucher from 'assets/svg/voucher';
import * as Styles from './styles';
import useClientId from 'hooks/use-client-id';

const DefaultLayout: React.FC = ({ children }) => {
  const client = useClientId();
  const isTcDeluxe = client.includes('deluxe');
  return (
    <Styles.Wrapper>
      <Styles.ContentWrapper>
        <Styles.MainContent>
          {children}
          <Footer />
        </Styles.MainContent>

        {!isTcDeluxe && (
          <RenderAt size="lg">
            <Styles.SideContent bg={bg}>
              <Styles.Overlay>
                <Styles.OverlayItem>
                  <SVG icon={Compass} fontSize={5} mb={2} />
                  <Styles.OverlayText>
                    Explore new adventures
                  </Styles.OverlayText>
                </Styles.OverlayItem>

                <Styles.OverlayItem>
                  <SVG icon={Calendar} fontSize={5} mb={2} />
                  <Styles.OverlayText>Manage reservations</Styles.OverlayText>
                </Styles.OverlayItem>

                <Styles.OverlayItem>
                  <SVG icon={Voucher} fontSize={5} mb={2} />
                  <Styles.OverlayText>Download vouchers</Styles.OverlayText>
                </Styles.OverlayItem>
              </Styles.Overlay>
            </Styles.SideContent>
          </RenderAt>
        )}
      </Styles.ContentWrapper>
    </Styles.Wrapper>
  );
};

export default DefaultLayout;
