import * as React from 'react';

import Layout from 'components/layouts/default';
import NewPasswordForm from 'components/new-password-form';
import useQueryParam from 'hooks/use-query-param';
import { Redirect } from 'react-router-dom';

const NewPasswordPage: React.FC = () => {
  const idParam = useQueryParam('id');
  if (!idParam) return <Redirect to="/login" />;

  return (
    <Layout>
      <NewPasswordForm id={idParam} />
    </Layout>
  );
};
export default NewPasswordPage;
