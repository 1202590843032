import styled from 'styled-components';

import media from 'util/styles/media-template';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;

  padding: ${({ theme }) => theme.space[3]};
  max-width: 448px;
  width: 100%;
  text-align: center;

  ${media.md} {
    border-radius: ${({ theme }) => theme.radii.rounded};
    box-shadow: ${({ theme }) => theme.shadows.blurred};
    padding: ${({ theme }) => theme.space[4]};
  }
`;

export const Form = styled.form`
  display: grid;
  grid-gap: ${({ theme }) => theme.space[3]};
`;

export const FormHeader = styled.div`
  margin-bottom: ${({ theme }) => theme.space[4]};
`;
