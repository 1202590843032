import {
  alignContent,
  alignItems,
  alignSelf,
  compose,
  flex as flexShorthand,
  flexBasis,
  flexDirection,
  flexWrap,
  gridArea,
  gridAutoColumns,
  gridAutoFlow,
  gridAutoRows,
  gridColumn,
  gridColumnGap,
  gridGap,
  gridRow,
  gridRowGap,
  gridTemplateAreas,
  gridTemplateColumns,
  gridTemplateRows,
  justifyContent,
  justifyItems,
  justifySelf,
  order,
  system,
} from 'styled-system';

export const alignment = compose(
  alignContent,
  alignItems,
  alignSelf,
  flexBasis,
  flexShorthand,
  gridArea,
  gridColumn,
  gridRow,
  justifyContent,
  justifyItems,
  justifySelf,
);

export const flex = compose(flexDirection, flexWrap, order);

export const grid = compose(
  gridAutoColumns,
  gridAutoFlow,
  gridAutoRows,
  gridColumnGap,
  gridGap,
  gridRowGap,
  gridTemplateAreas,
  gridTemplateColumns,
  gridTemplateRows,
);

export const textTransform = system({
  textTransform: {
    property: 'textTransform',
  },
});
