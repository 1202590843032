const PREFIX = 'tc_accountapp_';

export function get<A>(key: string): string | undefined {
  const item = window.sessionStorage.getItem(PREFIX + key);

  if (item) return item;

  return undefined;
}

export function set(key: string, data: any) {
  window.sessionStorage.setItem(PREFIX + key, data);
}

export function remove(key: string) {
  window.sessionStorage.removeItem(PREFIX + key);
}
