import * as React from 'react';

import Layout from 'components/layouts/default';
import ResetPasswordForm from 'components/reset-password-form';

const ResetPasswordPage: React.FC = () => {
  return (
    <Layout>
      <ResetPasswordForm />
    </Layout>
  );
};

export default ResetPasswordPage;
