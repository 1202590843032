import * as yup from 'yup';

import { FormValues } from './types';

const schema = yup.object().shape<FormValues>({
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .required('Please enter a password'),
  repeatedPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords do not match')
    .required(),
});

export default schema;
