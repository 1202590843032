import { TinyColor } from '@ctrl/tinycolor';
import themeGet from '@styled-system/theme-get';
import { StandardPropertiesHyphen } from 'csstype';
import { StyledPropsWithTheme } from 'styled-components';

import { Theme } from '../../themes/types';

type AnyObject = { [key: string]: any };
type Transitionable = keyof StandardPropertiesHyphen;
type TimingFn = StandardPropertiesHyphen['transition-timing-function'];

export const mkTransitions = (
  duration: string,
  timingFn: TimingFn = '',
  ...props: Transitionable[]
) => props.map((prop) => `${prop} ${duration} ${timingFn}`).join(',');

export function fromTheme<P extends AnyObject>(
  key: keyof Theme,
  val: string | undefined,
  props: StyledPropsWithTheme<P>,
) {
  return themeGet(`${key}.${val}`, val)(props);
}

/**
 * Takes a percentage value (i.e. a number between 0 and 1, inclusive)
 * and returns a hex color.
 */
export function toColor(val: number) {
  if (val > 1 || val < 0) {
    throw new Error(
      `Failed to translate ${val} to hex color; value ${val} falls outside of bounds [0, 1]`,
    );
  }

  if (val > 0.75) return '#3cac84';
  else if (val > 0.5) return '#ffbc42';
  else if (val > 0.25) return '#ef476f';
  return '#95190c';
}

/**
 * The same as `toColor` for the case where you want status
 * to improve as `val` approaches 0 instead of 1.
 */
export const inverseToColor = (val: number) => toColor(1 - val);

export const darken = (color?: string) =>
  color && new TinyColor(color).darken().desaturate().toHexString();

export const greyOut = (color?: string) =>
  color && new TinyColor(color).greyscale().toHexString();

export const lighten = (color?: string, amount = 0.1) =>
  color && new TinyColor(color).setAlpha(amount).toRgbString();
