import { useEffect, useState } from 'react';

function getParam(p: string) {
  const params = new URLSearchParams(window.location.search);

  return params.get(p);
}

function useQueryParam(param: string, fallback?: string) {
  const [qp, set] = useState(() => getParam(param) || fallback);

  useEffect(() => {
    set(getParam(param) || fallback);
  }, [fallback, param]);

  return qp;
}

export default useQueryParam;
