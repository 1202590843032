import * as React from 'react';

import { LinksProps as Props } from './types';

import { ExternalLink } from 'components/text';

import * as Styles from './styles';

const links = [
  { to: '/aboutus', label: 'About us' },
  { to: '/contactus', label: 'Contact us' },
  { to: '/faq', label: 'FAQs' },
  { to: '/privacy', label: 'Privacy' },
];

const TravelcoupLinks: React.FC<Props> = ({ locale }) => {
  return (
    <Styles.List>
      {links.map(({ to, label }) => (
        <Styles.ListItem key={to}>
          <ExternalLink href={`https://travelcoup.com/${locale}` + to}>
            {label}
          </ExternalLink>
        </Styles.ListItem>
      ))}
    </Styles.List>
  );
};

export default TravelcoupLinks;
