import { breakpoints } from 'themes/common';

type Breakpoints = keyof typeof breakpoints;
type Media = { [key in Breakpoints]: string };

const media: Media = (Object.keys(breakpoints) as Breakpoints[]).reduce(
  (a, k) => {
    a[k] = `@media screen and (min-width: ${breakpoints[k]})`;
    return a;
  },
  {} as Media,
);

export default media;
