import * as React from 'react';

import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
// import { useHistory } from 'react-router-dom';

import useStatus from 'hooks/use-status';
import { resetPassword } from 'services/srp-auth';
import { FormValues } from './types';
import validationSchema from './validation-schema';

import Button from 'components/button';
import Input from 'components/inputs/floating';
import FormErrors from 'components/form-errors';
import Logo from 'components/logo';
import StatusText from 'components/status-text';
import { Text } from 'components/text';
import useClientId from 'hooks/use-client-id';

import * as Styles from './styles';
import { useBaseUrl } from 'hooks/use-base-url';

interface OwnProps {
  id: string;
}

type Props = OwnProps;

const resolver = yupResolver<FormValues>(validationSchema);

const NewPasswordForm: React.FC<Props> = ({ id }) => {
  // const router = useHistory();
  const { status, Transition } = useStatus();
  const [apiError, setApiError] = React.useState('');
  const { register, handleSubmit, errors } = useForm({ resolver });

  const client = useClientId();
  const isTcDeluxe = client.includes('deluxe');
  const baseUrl = useBaseUrl();

  const onSubmit = (data: FormValues) => {
    setApiError('');
    Transition.loading();
    resetPassword(id, data.password).then((res) => {
      if (res.status === 'error') {
        setApiError(res.msg);
        Transition.error();
      } else {
        Transition.success();
        window.location.assign(baseUrl);

        // setTimeout(
        //   () => router.replace(`/login${window.location.search} `),
        //   1600,
        // );
      }
    });
  };

  return (
    <Styles.Wrapper style={{ background: isTcDeluxe ? '#282828' : 'auto' }}>
      <Logo mb={4} />
      {apiError && (
        <Text color="error.main" fontWeight="bold">
          {apiError}
        </Text>
      )}
      <FormErrors errors={errors} />
      <Styles.Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="New password"
          ref={register({ required: true })}
          inputProps={{
            name: 'password',
            type: 'password',
          }}
        />

        <Input
          label="Confirm password"
          ref={register({ required: true })}
          inputProps={{
            name: 'repeatedPassword',
            type: 'password',
          }}
        />

        <Button
          type="submit"
          disabled={status === 'loading'}
          kind="filled"
          variant="primary"
          py={3}
          mt={2}
        >
          <StatusText fontSize={2} message="Reset password" status={status} />
        </Button>
      </Styles.Form>
    </Styles.Wrapper>
  );
};

export default NewPasswordForm;
