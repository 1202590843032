import * as React from 'react';

import mkSvg from './styles';
import { StyledProps } from './types';

interface OwnProps {
  icon: React.ComponentType<SVGAttrs>;
  style?: React.CSSProperties;
}
type Props = OwnProps &
  Omit<StyledProps, 'fontSize' | 'fontFamily' | 'fontStyle' | 'fontWeight'> &
  SVGAttrs;

const SVG: React.FunctionComponent<Props> = ({ icon, ...rest }) => {
  const SSvg = React.useCallback(mkSvg(icon), [icon]);

  return <SSvg {...rest} />;
};

export default SVG;
export type SVGProps = StyledProps;
