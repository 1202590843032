import * as React from 'react';

import { useForm } from 'react-hook-form';

import { requestResetPassword } from 'services/srp-auth';
import useStatus from 'hooks/use-status';
import { FormValues } from './types';

import Button from 'components/button';
import Input from 'components/inputs/floating';
import FormErrors from 'components/form-errors';
import Logo from 'components/logo';
import StatusText from 'components/status-text';
import { Heading, Text } from 'components/text';

import * as Styles from './styles';
import useClientId from 'hooks/use-client-id';

const PasswordResetForm: React.FC = () => {
  const { status, Transition } = useStatus();
  const [apiErrors, setApiErrors] = React.useState('');
  const { register, handleSubmit, errors, getValues } = useForm<FormValues>();

  const [emailSent, setEmailSent] = React.useState(false);

  const client = useClientId();
  const isTcDeluxe = client.includes('deluxe');

  const onSubmit = (data: FormValues) => {
    setApiErrors('');
    Transition.loading();
    requestResetPassword(data.email).then((res) => {
      if (res.status === 'error') {
        setApiErrors(res.msg);
        Transition.error();
      } else {
        setEmailSent(true);
        Transition.success(2000);
      }
    });
  };

  return (
    <Styles.Wrapper style={{ background: isTcDeluxe ? '#282828' : 'auto' }}>
      <Logo mb={4} />
      <Heading fontSize={3} mb={3} color={isTcDeluxe ? '#eac554' : 'auto'}>
        Enter your email address to reset your password
      </Heading>
      {apiErrors && (
        <Text color="error.main" fontSize={1} mb={3}>
          {apiErrors}
        </Text>
      )}
      <FormErrors errors={errors} />
      <Styles.Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Email"
          ref={register({ required: true })}
          inputProps={{
            name: 'email',
            type: 'email',
          }}
        />

        <Button kind="filled" variant="primary" type="submit" py={3} mt={2}>
          <StatusText message="Reset password" status={status} fontSize={2} />
        </Button>
        {emailSent ? (
          <Text fontSize={1} mb={3} color={isTcDeluxe ? '#eac554' : 'auto'}>
            If there is an account associated with {getValues().email}, an email
            with password reset instructions will be sent to it.
          </Text>
        ) : null}
      </Styles.Form>
    </Styles.Wrapper>
  );
};

export default PasswordResetForm;
