import { useState } from 'react';

type Status = 'success' | 'error' | 'loading' | 'initial';

const mkTransitionFunc = (status: Status, set: (s: Status) => void) => (
  ms?: number,
): Promise<void> => {
  return new Promise((resolve) => {
    if (ms) {
      set(status);
      setTimeout(() => resolve(), ms);
    } else {
      resolve(set(status));
    }
  });
};

function useStatus(initial?: Status) {
  const [status, setStatus] = useState(initial || 'initial');

  const Transition: Record<Status, (ms?: number) => Promise<void>> = {
    error: mkTransitionFunc('error', setStatus),
    initial: mkTransitionFunc('initial', setStatus),
    loading: mkTransitionFunc('loading', setStatus),
    success: mkTransitionFunc('success', setStatus),
  };

  return { status, Transition };
}

export default useStatus;
