import * as React from 'react';

import { BrandContext } from 'App';
import { ProviderProps } from './types';

const Switch: React.FC = ({ children }) => {
  const provider = React.useContext(BrandContext);

  let element: React.FunctionComponentElement<ProviderProps> = null as any;

  React.Children.forEach(children, (child) => {
    if (element === null && React.isValidElement(child)) {
      const childProvider =
        (child.props.name as string) === provider ? provider : null;

      if (childProvider) {
        element = child as React.FunctionComponentElement<ProviderProps>;
      }
    }
  });

  if (element) {
    return React.cloneElement(element, element.props);
  } else {
    return null;
  }
};

export default Switch;
