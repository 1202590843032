import styled from 'styled-components';

import BaseInput from 'components/inputs/input';
import * as spacing from 'components/inputs/spacing';

export const Wrapper = styled.div`
  position: relative;
`;

export const Label = styled.label`
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: transform 0.3s ease-out, opacity 0.3s ease;
  left: ${spacing.PADDING_LEFT};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  top: ${({ theme }) => `calc(50% - ${theme.fontSizes[0]})`};
`;

export const Input = styled(BaseInput)`
  &:not(:placeholder-shown) {
    padding-top: ${spacing.FLOATING_LABEL_PADDING_TOP};
    padding-bottom: ${spacing.FLOATING_LABEL_PADDING_BOTTOM};
  }

  &:not(:placeholder-shown) + label {
    transform: translateY(-4px);
    opacity: 0.7;
  }
`;
